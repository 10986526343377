import { useEffect, useState } from "react";
import loadjs from "loadjs";
import axios from "axios";
import { v4 as uuidV4 } from "uuid";
import { Transition } from "@headlessui/react";

const AUTH_URL = "https://auth.hemayanjing.com/login";

let isRedirectUrl;
if (window.location.pathname === "/redirect_to_localhost_8002_login") {
  isRedirectUrl = true;
  window.location.href = "http://localhost:8002/login" + window.location.search;
} else if (
  window.location.pathname === "/redirect_to_manage_hemayanjing_com_showWxId"
) {
  isRedirectUrl = true;
  window.location.href =
    "https://manage.hemayanjing.com/showWxId" + window.location.search;
}

function App() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [showError, setShowError] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [showWxLogin, setShowWxLogin] = useState(false);

  useEffect(() => {
    const url = new URL(window.location.href);
    const code = url.searchParams.get("code");
    const appid = url.searchParams.get("appid");
    const pms = url.searchParams.get("pms");

    if (appid) {
      window.localStorage.appid = appid;
    }
    if (pms) {
      window.localStorage.pms = pms;
    }

    if (isRedirectUrl || !code) {
      return;
    }

    axios
      .post(
        AUTH_URL,
        {
          appid: window.localStorage.appid,
          grant_type: "wx_login",
          wx_code: code,
          request_permissions: (window.localStorage.pms || "").split(","),
        },
        {
          maxRedirects: 0,
        }
      )
      .then((res) => {
        window.location.href = res.data.redirect;
      })
      .catch((e) => {
        setErrMsg(e.response.data);
        setShowError(true);
      });
  }, []);

  useEffect(() => {
    if (!showWxLogin) {
      return;
    }

    (async function () {
      if (!loadjs.isDefined("wxLogin")) {
        await loadjs(
          ["https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js"],
          "wxLogin",
          {
            returnPromise: true,
          }
        );
      }

      new window.WxLogin({
        self_redirect: false,
        id: "wxauth",
        appid: "wx2bbdcf55a5073a69",
        scope: "snsapi_login",
        redirect_uri: "https://login.hemayanjing.com/",
        state: uuidV4(),
        style: "",
        href: "",
      });
    })();
  }, [showWxLogin]);

  if (isRedirectUrl) {
    return <div>跳转中...</div>;
  }

  return (
    <div>
      <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            登录
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form
              className="space-y-6"
              onSubmit={(e) => {
                e.preventDefault();
                if (
                  !username ||
                  username.length < 11 ||
                  !/1\d{10}/.test(username)
                ) {
                  setErrMsg("请输入正确的手机号");
                  setShowError(true);
                  return;
                }
                if (!password || password.length < 6) {
                  setErrMsg("请输入正确的密码");
                  setShowError(true);
                  return;
                }
                axios
                  .post(
                    AUTH_URL,
                    {
                      appid: window.localStorage.appid || "",
                      request_permissions: (
                        window.localStorage.pms || ""
                      ).split(","),
                      grant_type: "password",
                      username,
                      password,
                    },
                    {
                      maxRedirects: 0,
                    }
                  )
                  .then((res) => {
                    window.location.href = res.data.redirect;
                  })
                  .catch((e) => {
                    setErrMsg(e.response.data);
                    setShowError(true);
                  });
              }}
            >
              <div>
                <label
                  htmlFor="username"
                  className="block text-sm font-medium text-gray-700"
                >
                  用户名
                </label>
                <div className="mt-1">
                  <input
                    name="username"
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  密码
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  登录
                </button>
              </div>
            </form>

            <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300"></div>
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-white text-gray-500">或</span>
                </div>
              </div>

              <div className="mt-6 grid grid-cols-2 gap-3">
                <div>
                  <button
                    onClick={async () => {
                      setShowWxLogin(true);
                    }}
                    className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                  >
                    <span className="sr-only">微信登录</span>
                    <svg
                      t="1617416652207"
                      className="icon"
                      viewBox="0 0 1024 1024"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      p-id="1184"
                      width="20"
                      height="20"
                    >
                      <path
                        d="M1024 636.032c0-141.888-141.866667-257.429333-301.461333-257.429333-169.088 0-301.866667 115.541333-301.866667 257.429333 0 142.250667 132.778667 257.386667 301.866667 257.386667 35.370667 0 71.146667-9.024 106.496-17.642667l97.450667 53.418667-26.666667-88.789333C970.922667 786.965333 1024 715.84 1024 636.032zM624.618667 591.616c-17.642667 0-35.328-17.664-35.328-35.392 0-17.621333 17.685333-35.328 35.328-35.328 26.752 0 44.458667 17.706667 44.458667 35.328C669.077333 573.952 651.370667 591.616 624.618667 591.616zM820.010667 591.616c-17.664 0-35.306667-17.664-35.306667-35.392 0-17.621333 17.642667-35.328 35.306667-35.328 26.709333 0 44.416 17.706667 44.416 35.328C864.426667 573.952 846.293333 591.616 820.010667 591.616z"
                        p-id="1185"
                        fill="#6b7280"
                      ></path>
                      <path
                        d="M693.248 347.242667c11.626667 0 23.296 0.810667 34.901333 2.005333-31.274667-146.133333-187.392-254.464-365.674667-254.464C163.370667 94.784 0 230.442667 0 403.029333c0 99.562667 54.208 181.418667 144.917333 244.864L108.8 757.034667l126.826667-63.786667c45.354667 8.810667 81.877333 18.069333 126.848 18.069333 11.221333 0 22.506667-0.405333 33.749333-1.557333-7.232-24.128-11.242667-49.749333-11.242667-75.882667C384.96 475.690667 521.066667 347.242667 693.248 347.242667zM498.133333 248.896c27.285333 0 45.333333 18.069333 45.333333 45.376 0 27.264-18.069333 45.333333-45.333333 45.333333-27.306667 0-54.570667-18.069333-54.570667-45.333333C443.968 266.944 471.210667 248.896 498.133333 248.896zM244.458667 339.562667c-27.306667 0-54.570667-18.048-54.570667-45.333333 0-27.306667 27.285333-45.354667 54.570667-45.354667 27.328 0 45.397333 18.069333 45.397333 45.354667C289.834667 321.130667 271.786667 339.562667 244.458667 339.562667z"
                        p-id="1186"
                        fill="#6b7280"
                      ></path>
                    </svg>
                  </button>
                </div>

                <div>
                  <button
                    href="#"
                    disabled
                    className="bg-gray-200 w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-400"
                  >
                    <span className="sr-only">短信</span>
                    短信
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Transition show={showError}>
        <div
          className="fixed z-10 inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                aria-hidden="true"
              ></div>
            </Transition.Child>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <Transition.Child
              className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                  <svg
                    className="h-6 w-6 text-red-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    错误
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">{errMsg}</p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  onClick={() => {
                    setErrMsg("");
                    setShowError(false);
                  }}
                  className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                >
                  确定
                </button>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Transition>

      <Transition show={showWxLogin}>
        <div
          className="fixed z-10 inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                aria-hidden="true"
              ></div>
            </Transition.Child>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <Transition.Child
              className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="text-center">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  微信扫码登录
                </h3>
              </div>

              <div id="wxauth" className="px-5"></div>

              <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  onClick={() => {
                    setShowWxLogin(false);
                    window.location.reload();
                  }}
                  className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                >
                  确定
                </button>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Transition>
    </div>
  );
}

export default App;
